<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Compras / Listado de Compras</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Detalle Orden de Compra</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12 border-2">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h2>ORDEN DE COMPRA #{{ orden_compra.id }}</h2>
    </div>
    <div
      class="grid border-bottom-3"
      style="
        background-color: #f8f9fa;
        border-radius: 5px;
        border: 1px solid black;
      "
    >
      <div class="col-6">
        <p class="mb-0">
          <strong>EMPRESA: </strong>
          <span>{{ oc_recepcion.nombre_sucursal ?? "-" }}</span>
        </p>
        <p class="mb-0">
          <strong>SOLICITADO EL: </strong>
          <span>{{ orden_compra.fecha_registro }}</span>
        </p>
        <p class="mb-0">
          <strong>SOLICITADO POR: </strong>
          <span>{{ orden_compra.nombre_user }}</span>
        </p>
      </div>
      <div class="col-3">
        <p class="mb-0">
          <strong>PROVEEDOR: </strong>
          <span>{{ orden_compra.nombre_proveedor }}</span>
        </p>
        <p class="mb-0">
          <strong>ESTADO: </strong>
          <span>{{ orden_compra.estado_texto }}</span>
        </p>
      </div>
      <div class="col-3">
        <p class="mb-0">
          <strong>NRO. RECIBO/FACTURA: </strong>
          <span>{{ oc_recepcion.nro_recibo ?? "N/A" }}</span>
        </p>
        <p class="mb-0">
          <strong>RECIBIDO EL: </strong>
          <span>{{ oc_recepcion.fecha_recepcion ?? "N/A" }}</span>
        </p>
        <p class="mb-0">
          <strong>RECIBIDO POR: </strong>
          <span>{{ oc_recepcion.nombre_user ?? "N/A" }}</span>
        </p>
      </div>
    </div>
    <div class="grid mt-4">
      <DataTable
        ref="dt_oc"
        :value="productos"
        class="col-12 p-datatable-sm"
        showGridlines
        style="
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          line-height: 1.5;
        "
      >
        <Column field="codigo_interno" header="CÓD.INTERNO">
          {{ producto.codigo_interno }}
        </Column>
        <Column field="descripcion" header="DESCRIPCIÓN/PRODUCTO">
          {{ producto.descripcion }}
        </Column>
        <Column field="unidad_medida_nombre" header="UNIDAD DE MEDIDA">
          {{ producto.unidad_medida_nombre }}
        </Column>
        <Column class="text-right" field="pivot.cantidad" header="CANTIDAD">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.cantidad) }}
          </template>
        </Column>

        <Column class="text-right" field="pivot.precio" header="PRECIO COMPRA">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.precio) }}
          </template>
        </Column>
        <Column class="text-right" field="pivot.descuento" header="DESCUENTO">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(slotProps.data.pivot.descuento ?? 0)
            }}
          </template>
        </Column>
        <Column header="TOTAL" class="text-right">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.pivot.cantidad * slotProps.data.pivot.precio
              )
            }}
          </template>
        </Column>

        <template #footer>
          <div
            class="text-lg flex align-items-start justify-content-start"
            v-if="oc_recepcion.observacion"
          >
            <h6>
              <strong>OBSERVACIONES:</strong> {{ oc_recepcion.observacion }}
            </h6>
          </div>
          <div class="text-lg flex align-items-end justify-content-end">
            <h5>
              <strong>SUBTOTAL :</strong>
              {{ convertirNumeroGermanicFormat(total_general) }} Bs
            </h5>
          </div>
          <div class="text-lg flex align-items-end justify-content-end">
            <h5>
              <strong>DESCUENTO :</strong>
              {{
                convertirNumeroGermanicFormat(oc_recepcion.descuento_oc ?? 0)
              }}
              Bs
            </h5>
          </div>
          <div class="text-lg flex align-items-end justify-content-end">
            <h5>
              <strong>TOTAL COMPRA :</strong>
              {{
                convertirNumeroGermanicFormat(
                  total_general - (oc_recepcion.descuento_oc ?? 0)
                )
              }}
              Bs
            </h5>
          </div>
          <!-- <div class="text-lg flex align-items-end justify-content-end">
            <h5>
              <strong>Total Pagado :</strong>
              {{
                convertirNumeroGermanicFormat(
                  total_venta - (oc_recepcion.descuento_oc ?? 0)
                )
              }}
              Bs
            </h5>
          </div> -->
        </template>
      </DataTable>
    </div>
    <div class="flex col-12 justify-content-end">
      <Button @click="goBack" class="mr-2">
        <i class="pi pi-arrow-circle-left"></i>
        &nbsp;ATR&Aacute;S
      </Button>
      <Button
        label="Descargar"
        icon="pi pi-file-pdf"
        class="p-button-danger p-button-lg"
        @click="imprimirOC(orden_compra.id)"
        :disabled="imprimiendo"
        :loading="imprimiendo"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import OrdenCompraService from "@/service/OrdenCompraService";
import SucursalService from "@/service/SucursalService";

export default {
  data() {
    return {
      orden_compra: {},
      oc_recepcion: {},
      productos: [],
      idOrdencompra: this.$route.params.id,
      texto_estado: "",
      total_venta: 0,
      imprimiendo: false,
    };
  },
  ordenCompraService: null,
  sucursalService: null,
  created() {
    this.ordenCompraService = new OrdenCompraService();
    this.sucursalService = new SucursalService();
  },
  mounted() {
    this.obtenerOndenCompras();
  },
  computed: {
    total_general() {
      let total = 0;
      this.productos.forEach((producto) => {
        total +=
          parseFloat(producto.pivot.cantidad * producto.pivot.precio) +
          parseFloat(producto.pivot.descuento ?? 0);
      });
      return total;
    },
  },
  methods: {
    imprimirOC(id) {
      if (this.imprimiendo) {
        return;
      }
      this.imprimiendo = true;
      this.ordenCompraService
        .imprimirOC(id)
        .then(() => {
          this.imprimiendo = false;
        })
        .catch((error) => {
          console.log(error);
          this.imprimiendo = false;
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    obtenerOndenCompras() {
      this.ordenCompraService
        .obtenerOrdenCompra(this.idOrdencompra)
        .then((data) => {
          this.orden_compra = data.oc ? data.oc : {};
          this.productos = data.productos ? data.productos : [];
          this.oc_recepcion = data.oc_recepcion ? data.oc_recepcion : {};
          this.productos.forEach((producto) => {
            this.producto = producto;
          });
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    productos() {
      this.total_venta = 0;
      this.productos.forEach((producto) => {
        this.total_venta += producto.pivot.cantidad * producto.pivot.precio;
      });
    },
  },
};
</script>
